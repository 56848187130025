import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Branded headings are primarily used for grouping rows of article teasers. Since
Chameleon strongly believes in the importance of brand identity, each brand has
the freedom to customize the component to their needs.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Most of these components are still being designed (mobile and
desktop) in close collaboration with the brands. To get a final status please
contact someone from the design team. The embed below gives you already a
glimpse about the branding possibilities.`}</p>
    </blockquote>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=578%3A1" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "sizing",
      "style": {
        "position": "relative"
      }
    }}>{`Sizing`}</h2>
    <p>{`Each brand has the option to choose two branded heading sizes, which will be
translated both for mobile and desktop.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      